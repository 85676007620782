/* eslint-disable import/prefer-default-export */
export const SETTING_PIN = 'v1/kompack/setting/pin'
export const SETTING_PROFILE = 'v1/kompack/setting/profile'
export const STORE_PIN = 'v1/kompack/setting/store/pin'
export const CONFIRM_PIN = 'v1/kompack/setting/confirm/pin'
export const UPDATE_PIN = 'v1/kompack/setting/update/pin'
export const SEND_OTP_EMAIL = 'v1/send-otp-email'
export const VARIFY_OTP_EMAIL = 'v1/varify-otp-email'
export const SMS_OTP = 'v2/sms/otp'
export const OTP_VERIFICATION = 'v2/sms/otp/verification'
export const UPDATE_PROFILE = 'v1/kompack/setting/update/profile'
export const UPDATE_PASSWORD = 'v1/kompack/setting/update/password'
export const WAREHOUSE_PARTNER = '/v1/kompack/warehouse/partner'
export const SEND_LINK_RESET_PASSWORD = 'v1/mitra-gudang/send-link-reset-password'
export const RESET_PASSWORD = 'v1/mitra-gudang/reset-password'
export const BANK_ACCOUNT = 'v1/kompack/setting/bank-account'
export const CHECK_BANK_ACCOUNT = 'v1/bank/check-bank-owner'
export const STORE_BANK_ACCOUNT = 'v1/kompack/setting/store/bank-account'
export const UPDATE_BANK_ACCOUNT = 'v1/kompack/setting/update/bank-account'
export const DELETE_BANK_ACCOUNT = 'v1/kompack/setting/delete/bank-account'
export const INBOUND = '/v1/kompack/inbound'
export const SELECT_PARTNERS = '/v1/kompack/inbound/select-option/partners'
export const STOCK = '/v1/kompack/warehouse/stock'
export const OUTBOUND = '/v1/kompack/outbound'
export const OUTBOUND_PARTNER = '/v1/kompack/outbound/select-option/partner'
export const MONITORING_PICKUP = '/v1/mitra/monitoring/pickup'
export const SHIPMENTS = '/v1/kompack/shipments'
export const RAJAONGKIR_SHIPPING = '/v1/kompack/outbound/non-pickup/rajaongkir-shippings'
export const OUTBOUND_NON_PICKUP = '/v1/kompack/outbound/non-pickup'
export const CHECK_WHATSAPP = '/v1/check-wa'
export const SEND_OTP_WA = '/v1/wa/otp'
export const VERIFY_OTP_WA = '/v1/wa/otp/verification'
export const CHECK_NO_MITRA = '/v1/kompack/setting/mitra-check-no'
export const DOWNLOAD_MONITORING_PICKUP = '/v1/pickup/export-excel'
export const PRINT_LABEL = 'v2/generate/print-label'
